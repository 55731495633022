<template>
  <v-card width="350">
    <v-col align="center">
      <h3 class="mt-5 mb-2" align="center">Паспорт объекта</h3>
      <v-row dense>
        <v-col cols="12" sm="4">
          <v-card-text class="text-end">Название:</v-card-text>
        </v-col>
        <v-col cols="12" sm="8">
          <v-card-text class="text-start">{{ architectureName }}</v-card-text>
        </v-col>
      </v-row>
      <div class="mt-2">
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">
              <v-btn
                type="button"
                class="font-weight-bold"
                @click="setArchutecture"
                >Сохранить</v-btn
              >
            </v-card-text>
          </v-col>
          <v-col cols="12" sm="6" justify="end">
            <v-card-text class="text-start"
              ><v-btn
                type="button"
                class="font-weight-bold"
                v-on:click="$emit('remove-architecture')"
                >Удалить</v-btn
              ></v-card-text
            >
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-card>
</template>

<script>
export default {
  name: "ArchitectPassport",

  props: {
    setArchutecture: Function,
    architectureName: String
  },

  data: () => ({})
};
</script>

<style scoped>
::v-deep .v-snack__content {
  width: 100vw;
}
::v-deep .v-snack__wrapper {
  max-width: 400px;
}
</style>
