<template>
  <v-card style="width: 420px">
    <v-form
      ref="form"
      v-model="formValidity"
      @submit.prevent="
        setSquare({
          name: nameTarget,
          address: addressTarget,
          area: areaTarget,
          year: yearTarget,
          plant_amount: plantAmountTarget,
          foliage_amount: foliageAmountTarget,
          pine_amount: pineAmountTarget,
          bush_amount: bushAmountTarget,
          hedge_length: hedgeLengthTarget,
          lawn_area: lawnAreaTarget,
          garden_area: gardenAreaTarget,
          architecture_amount: architectureAmountTarget,
          fountain_amount: fountainAmountTarget,
          beautification_amount: beautificationAmountTarget,
          bench_amount: benchAmountTarget,
          trashcan_amount: trashcanAmountTarget,
          lamp_amount: lampAmountTarget,
          irrigation_length: irrigationLengthTarget,
          road_length: roadLengthTarget,
          company: companyTarget
        })
      "
    >
      <v-col align="center">
        <h3 class="mt-5 mb-2">Паспорт объекта</h3>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start"
              >Идентификационный номер</v-card-text
            >
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">{{ properties.id }}</v-card-text>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Название</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="nameTarget"
              type="text"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Район</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">{{
              district ? district.name : ""
            }}</v-card-text>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Адрес</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="addressTarget"
              type="text"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Площадь</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="areaTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Год закладки</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="yearTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Зеленые насаждения</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="plantAmountTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Лиственные породы</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="foliageAmountTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Хвойные породы</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="pineAmountTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Кустарники</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="bushAmountTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Живая изгородь</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="hedgeLengthTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Газон</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="lawnAreaTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Цветник</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="gardenAreaTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start"
              >Малые архитектурные формы</v-card-text
            >
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="architectureAmountTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Фонтаны</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="fountainAmountTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start"
              >Элементы благоустройства</v-card-text
            >
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="beautificationAmountTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Скамейки</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="benchAmountTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Урны</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="trashcanAmountTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Освещение</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="lampAmountTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Арычная сеть</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="irrigationLengthTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start"
              >Дорожно-тропиночная сеть</v-card-text
            >
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="roadLengthTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Обслуживающая компания</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="companyTarget"
              type="text"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="mt-2">
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-card-text class="text-end">
                <v-btn
                  type="submit"
                  class="font-weight-bold"
                  :disabled="!formValidity"
                  >Сохранить</v-btn
                >
              </v-card-text>
            </v-col>
            <v-col v-if="isDraw" cols="12" sm="6" justify="end">
              <v-card-text class="text-start"
                ><v-btn
                  type="button"
                  class="font-weight-bold"
                  @click="resetForm"
                  >Очистить</v-btn
                ></v-card-text
              >
            </v-col>
            <v-col v-else cols="12" sm="6" justify="end">
              <v-card-text class="text-start"
                ><v-btn
                  type="button"
                  class="font-weight-bold"
                  v-on:click="$emit('remove-square')"
                  >Удалить</v-btn
                ></v-card-text
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "SquarePassport",

  props: {
    setSquare: Function,
    properties: Object,
    isDraw: Boolean,
    district: Object
  },

  data() {
    return {
      nameTarget: null,
      addressTarget: null,
      areaTarget: null,
      yearTarget: null,
      plantAmountTarget: null,
      foliageAmountTarget: null,
      pineAmountTarget: null,
      bushAmountTarget: null,
      hedgeLengthTarget: null,
      lawnAreaTarget: null,
      gardenAreaTarget: null,
      architectureAmountTarget: null,
      fountainAmountTarget: null,
      beautificationAmountTarget: null,
      benchAmountTarget: null,
      trashcanAmountTarget: null,
      lampAmountTarget: null,
      irrigationLengthTarget: null,
      roadLengthTarget: null,
      companyTarget: null,
      formValidity: false
    };
  },

  watch: {
    properties: function(newValue) {
      this.nameTarget = newValue.name;
      this.addressTarget = newValue.address;
      this.areaTarget = newValue.area;
      this.yearTarget = newValue.year;
      this.plantAmountTarget = newValue.plant_amount;
      this.foliageAmountTarget = newValue.foliage_amount;
      this.pineAmountTarget = newValue.pine_amount;
      this.bushAmountTarget = newValue.bush_amount;
      this.hedgeLengthTarget = newValue.hedge_length;
      this.lawnAreaTarget = newValue.lawn_area;
      this.gardenAreaTarget = newValue.garden_area;
      this.architectureAmountTarget = newValue.architecture_amount;
      this.fountainAmountTarget = newValue.fountain_amount;
      this.beautificationAmountTarget = newValue.beautification_amount;
      this.benchAmountTarget = newValue.bench_amount;
      this.trashcanAmountTarget = newValue.trashcan_amount;
      this.lampAmountTarget = newValue.lamp_amount;
      this.irrigationLengthTarget = newValue.irrigation_length;
      this.roadLengthTarget = newValue.road_length;
      this.companyTarget = newValue.company;
    }
  },

  methods: {
    resetForm() {
      this.$refs.form.reset();
    }
  }
};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>
